body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}


header.masthead {
    padding-top: 10rem;
    padding-bottom: calc(10rem - 72px);
    /*background: -webkit-gradient(linear, left top, left bottom, from(rgba(92, 77, 66, 0.8)), to(rgba(92, 77, 66, 0.8))), url("../img/bg-masthead.jpg");*/
    background: linear-gradient(to bottom, rgba(92, 77, 66, 0.8) 0%, rgba(92, 77, 66, 0.8) 100%), url("https://i.imgur.com/IwWqGl3.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;
}

header.masthead h1 {
    font-size: 2.25rem;
}

@media (min-width: 992px) {
    header.masthead {
        height: 100vh;
        min-height: 40rem;
        padding-top: 72px;
        padding-bottom: 0;
    }

    header.masthead p {
        font-size: 1.15rem;
    }

    header.masthead h1 {
        font-size: 3rem;
    }
}

.login-error {
    color: red;
}

.bg-gray {
    background: rgba(204, 204, 204, 0.705);
}

.card-shadow {
    -webkit-box-shadow: 0px 6px 21px -7px rgba(0, 0, 0, 0.44);
    -moz-box-shadow: 0px 6px 21px -7px rgba(0, 0, 0, 0.44);
    box-shadow: 0px 6px 21px -7px rgba(0, 0, 0, 0.44);
}

h1 {
    font-size: 2em !important;
}